<template>
  <b-overlay
    :show="isLoading"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title class="px-2 pt-2">
        ยอดเสียรายวัน
      </b-card-title>

      <b-card-body>
        <b-row>
          <b-col
            md="4"
            lg="2"
          >
            <b-form-group label="ประเภท">
              <b-form-select
                v-model="search_type"
                :options="search_type_option"
                placeholder="เลือกประเภทที่จะค้นหา"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            lg="2"
          >
            <b-form-group label="เลือกวันที่เริ่มต้น (ตามยอดเสียของวันที่)">
              <flat-pickr
                v-model="dateStart"
                placeholder="เลือกวันที่"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            lg="2"
          >
            <b-form-group label="เลือกวันที่สิ้นสุด (ตามยอดเสียของวันที่)">
              <flat-pickr
                v-model="dateEnd"
                placeholder="เลือกวันที่"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            lg="2"
          >
            <b-form-group label="ยูส">
              <b-form-input
                v-model="term"
                placeholder="ค้นหายูส/เบอร์"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            lg="2"
            class="d-flex justify-content-center align-items-center mt-1"
          >
            <b-button
              block
              variant="primary"
              @click="getData()"
            >
              ค้นหา
            </b-button>
          </b-col>
          <b-col
            md="4"
            lg="2"
            class="d-flex justify-content-center align-items-center mt-1"
          >
            <b-button
              v-b-modal.modal-cashback-wl
              block
              variant="success"
            >
              EXPORT
            </b-button>
          </b-col>
        </b-row>

      </b-card-body>

      <div class="row px-2">
        <div class="col-12 col-md-4">
          <b-alert
            small
            show
            variant="primary"
            class="text-center"
          >
            <div>
              <h3 class="alert-heading">
                ไม่ได้รับยอดเสีย
                <div>{{ dateStart }} ถึง {{ dateEnd }}</div>
              </h3>
            </div>
            <div class="py-2 h2 text-primary">
              {{ total_not_cashback }}
            </div>
          </b-alert>
        </div>
        <div class="col-12 col-md-4">
          <b-alert
            small
            show
            variant="primary"
            class="text-center"
          >
            <div>
              <h3 class="alert-heading">
                ยังไม่รับ
                <div>{{ dateStart }} ถึง {{ dateEnd }}</div>
              </h3>
            </div>
            <div class="py-2 h2 text-primary">
              {{ totalNotReceive }}
            </div>
          </b-alert>
        </div>
        <div class="col-12 col-md-4">
          <b-alert
            small
            show
            variant="primary"
            class="text-center"
          >
            <div>
              <h3 class="alert-heading">
                รับแล้ว
                <div>{{ dateStart }} ถึง {{ dateEnd }}</div>
              </h3>
            </div>
            <div class="py-2 h2 text-primary">
              {{ totalReceive }}
            </div>
          </b-alert>
        </div>
      </div>

      <b-card-body class="p-0 m-0">
        <b-table
          striped
          hover
          small
          responsive
          show-empty
          class="mt-2 position-relative items-center"
          :per-page="perPage"
          :items="items"
          :fields="fields"
        >

          <template #empty="scope">
            <div class="p-2 text-center">
              <img
                src="/box.png"
                alt="empty"
                height="40px"
              > {{ scope.emptyFiltered ? 'No matching records found' :
                'ไม่พบข้อมูล' }}
            </div>
          </template>
          <template #cell(created_at)="{ item }">
            {{ item.created_at | dateFormat }}
          </template>
          <template #cell(stamp_date)="{ item }">
            {{ item.stamp_date | dateFormat2 }}
          </template>
          <template #cell(cashback_receive)="{ item }">
            <b-badge
              v-if="item.wl_amount < 0"
              pill
              :variant="`${item.cashback_receive ? 'light-success' : 'light-warning'}`"
            >
              {{ item.cashback_receive ? 'รับแล้ว' : 'ยังไม่รับ' }}
            </b-badge>
            <b-badge
              v-else
              variant="light-secondary"
              pill
            >
              ไม่ได้รับยอดเสีย
            </b-badge>
          </template>
        </b-table>
        <div class="card-body d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
              @change="getData()"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="getData()"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-cashback-wl"
      title="ระบุรหัสผ่าน"
      @ok="checkExportPassword"
    >
      <b-form-input v-model="exportPassword" />
    </b-modal>
  </b-overlay>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'
import {
  BModal, BAlert, BBadge, BPagination, BFormSelect, BTable, BPaginate, BIconController, BButton, BFormInput, BRow, BCol, BCardBody, BCard, BCardTitle, BFormGroup, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BAlert,
    BBadge,
    BPagination,
    BFormSelect,
    BTable,
    BPaginate,
    BIconController,
    BButton,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    BCard,
    BCardTitle,
    BFormGroup,
    BFormCheckbox,
    BOverlay,

    flatPickr,
  },
  filters: {
    dateFormat(val) {
      return moment(val).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm')
    },
    dateFormat2(val) {
      return moment(val).tz('Asia/Bangkok').format('YYYY-MM-DD')
    },
  },
  data: () => ({
    perPage: 50,
    pageOptions: [50, 100, 200],
    totalRows: 50,
    page: 1,
    items: [],
    search_type: 1,
    exportPassword: '',
    search_type_option: [
      { value: 1, text: 'ทั้งหมด' },
      { value: 2, text: 'รับแล้ว' },
      { value: 3, text: 'ยังไม่รับ' },
      { value: 4, text: 'ไม่ได้รับยอดเสีย' },
    ],
    rangeDate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    fields: [
      { key: 'username', label: 'ยูส' },
      { key: 'tel', label: 'เบอร์' },
      { key: 'turnover', label: 'Turnover' },
      { key: 'wl_amount', label: 'Win/Lost' },
      { key: 'cashback_receive', label: 'สถานะ' },
      { key: 'stamp_date', label: 'ยอดเสียของวันที่' },
      { key: 'created_at', label: 'วันที่ดึงข้อมูล' },
    ],
    isLoading: false,
    dateStart: moment().tz('Asia/Bangkok').subtract(1, 'days').format('YYYY-MM-DD'),
    dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    search_from: null,
    term: '',
    totalNotReceive: 0,
    total_not_cashback: 0,
    totalReceive: 0,
  }),
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      // const startDate = Array.isArray(this.rangeDate) ? this.rangeDate[0] : this.rangeDate.split(' ')[0]
      // const endDate = Array.isArray(this.rangeDate) ? this.rangeDate[1] : this.rangeDate.split(' ')[2]
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/cashback/wl', {
          params: {
            page: this.page,
            perPage: this.perPage,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            term: this.term,
            search_type: this.search_type,
          },
        })

        this.totalRows = data.pagination.total
        this.items = data.data
        this.totalNotReceive = data.total_not_receive
        this.total_not_cashback = data.total_not_cashback
        this.totalReceive = data.total_receive
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async checkExportPassword() {
      const obj = {
        password: this.exportPassword,
      }
      this.$http
        .post('/export/checkpass', obj)
        .then(response => {
          if (response.data.status === true) {
            this.exportData()
          } else {
            this.$swal({
              icon: 'error',
              title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
              text: 'รหัสผ่านไม่ถูกต้อง',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }
        })
        .catch(error => console.log(error))
    },

    async exportData() {
      // const startDate = Array.isArray(this.rangeDate) ? this.rangeDate[0] : this.rangeDate.split(' ')[0]
      // const endDate = Array.isArray(this.rangeDate) ? this.rangeDate[1] : this.rangeDate.split(' ')[2]
      try {
        const { data } = await this.$http.get('/cashback/export_wl', {
          responseType: 'blob',
          params: {
            page: this.page,
            perPage: this.perPage,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            term: this.term,
            search_type: this.search_type,
          },
        })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(
          new Blob([data]),
        )

        link.setAttribute('download', 'Report.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (error) {

      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
